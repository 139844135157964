<template>
  <div fluid id="EmpreendimentoConsulta" class="pa-0" v-bind:style="{ 'background-color': COR_PRINCIPAL }">

    <!-- Usado para selecionar o texto consultado e deixar ele HighLight -->
    <!-- <input type="hidden" id="hid_search_text" name="hid_search_text"> -->

    <v-toolbar-title v-bind:style="{ 'background-color': COR_PRINCIPAL }" class="headline lighten-2">

      <!-- TITULO DA PÁGINAS -->
      <v-toolbar-title class="text-white title-page mt-3 ml-4 w-100">Empreendimentos
      </v-toolbar-title>
      <!-- Fim Título da página -->

      <!-- Botões de ação cabeçalho -->
      <v-toolbar-title class="mt-0 mb-3 ml-4 pa-0 d-flex flex-column w-100" dark elevation="0">
        <div class="d-flex align-center justify-space-between w-100" tabindex="-1">
          <v-text-field id="txtBuscar" ref="txtBuscar" autofocus v-on:keyup="filtro = $event.target.value"
            prepend-inner-icon="mdi-magnify" dense v-model="search" class="search-input"
            v-bind:class="{ 'search-input-longo': search_input_focus }" flat dark label="Buscar..." solo-inverted
            @focus="onFocus($event)" @blur="onBlur($event)" tabindex="-1">
          </v-text-field>

          <div>
            <router-link :to="{ name: 'EmpreendimentoContainer' }" class="nav-link" aria-current="page" tabindex="-1">
              <v-btn class="text-white text-capitalize" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
                <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
                <span>Novo</span>
              </v-btn>
            </router-link>

            <v-btn class="text-white text-capitalize mr-2" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
              <v-icon class="mr-1" color="light-blue accent-2">mdi-view-headline</v-icon>
              <span>Opções</span>
            </v-btn>
          </div>
        </div>
      </v-toolbar-title>
      <!-- Fim Botões de ação cabeçalho -->
    </v-toolbar-title>

    <v-row class="w-100 mx-0">
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="pa-0 mx-0 w-100">
        <v-container class="container-principal container-rounded rounded-lg px-0 w-100 mx-0">
          <!-- Conteiner  -->

        <v-data-table
          id="virtualScrollTable"
          ref="virtualScrollTable"
          :items="itensFiltro"
          :headers="headers"
          :loading="loading"
          fixed-header
          dense
          :height="tableHeight"
          :items-per-page="20"
          hide-default-header
          hide-default-footer
          loading-text="Carregando...  aguarde..."
          no-data-text="Nenhum Registro Encontrado"
          no-results-text="Nenhum Registro Encontrado"
          class="data-table">
            <!-- @update:page="$vuetify.goTo($refs.virtualScrollTable)" -->
            <template v-slot:header v-if="!isMobile">
              <thead>
                <tr>
                  <th v-for="h in headers" :key="h.value">
                    <span>{{ h.text }}</span>
                  </th>
                </tr>
              </thead>
            </template>

            <template #item="{ item }">
              <tr v-if="!isMobile">
                <td style="width: 5%; padding: 0;">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list class="py-0">
                      <v-list-item v-for="(item_menu, i) in items" :key="i" @click="() => { }" class="px-2">

                        <v-list-item-title v-if="item_menu.title == 'Editar'">
                          <router-link :to="'/empreendimentocontainer/' + item.cod_empreendimento" class="nav-link"
                            aria-current="page">
                            <v-icon color="green" class="mr-2 icon-menu">mdi-pencil-outline
                            </v-icon>
                            {{ item_menu.title }}
                          </router-link>
                        </v-list-item-title>

                        <v-list-item-title v-else-if="item_menu.title == 'Excluir'">
                          <div
                            @click="dialog_excluir = true; nomeEmpreendimentoAux = item.empreend_nome; codEmpreendimento_Aux = item.cod_empreendimento">
                            <v-icon color="red" class="mr-2 icon-menu">mdi-delete-outline</v-icon>

                            {{ item_menu.title }}
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>

                <td style="width: 5%" class="td-avatar py-1 pl-1">
                  <!-- <pre>{{ item }}</pre> -->
                  <v-avatar size="50">
                    <v-img :src="item.empreend_logo"></v-img>
                  </v-avatar>
                </td>

                <td>
                  {{ item.empreend_nome }}
                </td>

                <td class="text-align-center">
                  <span v-if="item.empreend_situacao.toUpperCase() == 'ATIVO'" class="status_ativo">{{
                    item.empreend_situacao.initCap() }}
                  </span>

                  <span v-if="item.empreend_situacao.toUpperCase() == 'INATIVO'" class="status_inativo">{{
                    item.empreend_situacao.initCap() }}
                  </span>
                </td>

                <td>
                  {{ item.empreend_matr_numero }}
                </td>

                <td>
                  {{ formatDate(item.empreend_matr_data) }}
                </td>

                <td>
                  {{ item.empreend_area }}
                </td>
              </tr>

              <!-- MOBILE ------------------------------------------------------------------------------- -->
              <tr v-if="isMobile">
                <td style="border:0">
                  <div style="width:25px !important" class="ml-n2">
                    <v-avatar size="45" class="ml-n1">
                      <v-img :src="item.foto"> </v-img>
                    </v-avatar>
                  </div>
                </td>

                <td style="width: auto">
                  <div class="mt-1" style="font-size: 14px; font-weight:500">
                    {{ item.empreend_nome }}
                  </div>
                  <div class="mt-1" style="font-size: 12px">
                    {{ item.empreend_situacao }}
                  </div>
                  <div class="mt-1" style="font-size: 12px">
                    {{ item.numero_matricula }}
                  </div>
                  <div class="mt-1" style="font-size: 12px">
                    {{ item.data_matricula }}
                  </div>
                </td>

                <td>
                  <div style="width:25px !important" class="ml-n4">

                    <router-link :to="{
                      name: 'EmpreendimentoContainer',
                    }" class="nav-link" aria-current="page">
                      <v-btn icon dark class="btn-icon" color="grey">
                        <v-icon class="icon-action"> mdi-pencil </v-icon>
                      </v-btn>
                    </router-link>
                  </div>
                </td>

              </tr>
            </template>
          </v-data-table>

        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->
    </v-row>

    <!--------- DIALOG EXCLUIR EMPREENDIMENTO ------------------------------->
    <v-dialog v-model="dialog_excluir" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
      <v-divider></v-divider>

      <v-card elevation="0" class="">
        <v-card-title class="text-h6">
          Tem certeza que deseja EXCLUIR este documento?
        </v-card-title>
        <v-card-text>
          <br>
          <span style="font-size: 15px; font-weight:600">Nome: {{ nomeEmpreendimentoAux }}</span><br>

        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir = false">
            Cancelar
          </v-btn>

          <v-btn class="btn white--text" color="primary accent-4" :loading="loading_excluir" @click="excluir()">
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { COR_PRINCIPAL } from "../../services/constantes";
import store_Empreendimento from "./store_Empreendimento";
import store_site from "../../store/store_site";

import { API } from "../../services/API";
import {
  formatDateTime,
  formatDate,
  searchHighlight,
  highlightAll,
  removeHighLight,
} from "../../services/funcoes";

export default {
  name: "EmpreendimentoConsulta",
  components: {
  },

  data() {
    return {

      store_Empreendimento: store_Empreendimento,
      store_site: store_site,
      loading: false,
      search: '',
      selected: null,
      search_input_focus: false,
      nomeEmpreendimentoAux: null,
      codEmpreendimento_Aux: null,

      loading_excluir: false,
      filtro: null,
      currentPage: 1,
      formatDateTime: formatDateTime,
      formatDate: formatDate,
      COR_PRINCIPAL: COR_PRINCIPAL,
      accentColor: '#f00',
      dialog_excluir: false,
      dialog_excluir_alert: false,
      dialog_excluir_msg: false,
      dialog_excluir_message: null,

      /* TÍTULO GRADE */
      headers: [
        { text: "" },
        { text: "", sortable: false, class: 'title-text', class: 'title-text' },
        { text: "Nome", value: "empreend_nome", sortable: true, class: 'title-text' },
        { text: "Situação", value: "empreend_situacao", class: 'title-text' },
        { text: "Número Matrícula", value: "numero_matricula", class: 'title-text' },
        { text: "Data Matrícula", value: "data_matricula", class: 'title-text' },
        { text: "Área", value: "area", class: 'title-text' },
      ],

      /* GRADE CONSULTA */
      dados: [],
      itensFiltro: [],

      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],

    };
  },

  created() {
    this.busca_Empreendimento({ params: { fields: "cod_empreendimento, empreend_nome, empreend_situacao, empreend_matr_numero, empreend_matr_data, empreend_area, empreend_logo" } });
  },

  watch: {
    search(val) {
      this.currentPage = 1;
      this.busca_timeout(val);
    },
    // itensFiltro() {
    //   clearTimeout(this._searchTimerId_2);
    //   this._searchTimerId_2 = setTimeout(() => {
    //     searchHighlight('EmpreendimentoConsulta', this.search);
    //   }, 400);
    // }
  },

  computed: {
    // itensFiltro() {
    //   let valores = null;
    //   //const result = Array.isArray(this.dados) ? this.dados.filter(num => num % 2 === 0) : [];
    //   if (Array.isArray(this.dados)) {
    //     //valores = this.dados.filter(x => x.empreend_nome.toLowerCase().includes(this.search.toLowerCase()))
    //     valores = this.dados.filter((item) => {
    //                                             return JSON.stringify(item.empreend_nome)
    //                                               .toLowerCase()
    //                                               .indexOf(this.search.toLowerCase()) > -1
    //                                           }
    //     )
    //     if (!valores)
    //       return [];
    //     // valores = this.dados.filter((item) => {
    //     //   return (
    //     //     item.empreend_nome.toLowerCase().indexOf(this.search.toLowerCase()) > -1 
    //     //   )
    //     // })
    //     return valores;
    //   }
    //   else
    //     return []
    //   // valores = this.dados.filter((item) => {
    //   //     item.empreend_situacao.toLowerCase().indexOf(this.search.toLowerCase()) > -1 
    //   // })
    // },

    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 90;
      else
        return window.innerHeight - 149 - 50;
    }

  },

  methods: {

    async excluir() {
      this.loading_excluir = true;

      const ls_Resp = await this.store_Empreendimento.EmpreendimentoDelete({
        cod_empreendimento: this.codEmpreendimento_Aux
      });

      // console.log('ls_Resp', ls_Resp)

      if (ls_Resp.message == "success") {
        this.store_site.alert_cor = "green";
        this.store_site.alert_type = "success";
        this.store_site.alert_msg = ls_Resp.result;
        this.store_site.alert = true;
      } else {
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_cor = "red";
        this.store_site.alert_type = "warning";
        this.store_site.alert_msg = ls_Resp.errors;
        this.store_site.alert = true;
      }
      this.loading_excluir = false;
      this.dialog_excluir = false;

      const i = this.dados.findIndex(element => element.cod_empreendimento == this.codEmpreendimento_Aux)
      if (i !== -1) {
        this.dados.splice(i, 1)
      }
    },

    async busca_Empreendimento(p_params = {}) {
      try {
        const response = await API.get("empreendimento", p_params);
        if (response) {
          if (response.data.result.data == "Não possui dados") {
            this.dados = [];
          } else {
            this.dados = response.data.result;
            this.itensFiltro = this.dados;
          }
        }
      }
      catch (err) {
        console.log("err", err);
      }
    },

    busca_timeout(val) {
      if (val) {
        val = val.trim();
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        //this.busca_Empreendimento( { params: { filtro: val, fields: "cod_empreendimento, empreend_nome, empreend_situacao" } } );
        this.itensFiltro = [];
        let valores = null;
        if (Array.isArray(this.dados)) {
          //valores = this.dados.filter(x => x.empreend_nome.toLowerCase().includes(this.search.toLowerCase()))
          // valores = this.dados.filter((item) => {
          //   return (
          //     item.empreend_nome.toLowerCase().indexOf(this.search.toLowerCase()) > -1 
          //   )
          // })
          valores = this.dados.filter((item) => {
            return JSON.stringify(item.empreend_nome)
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
          }
          )
          // if (valores && valores.length > 0)
          //   this.itensFiltro = [...valores];
          // console.log('VALORES', this.itensFiltro);
        }
        // valores = this.dados.filter((item) => {
        //     item.empreend_situacao.toLowerCase().indexOf(this.search.toLowerCase()) > -1 
        // })
        clearTimeout(this._searchTimerId_2);
        this._searchTimerId_2 = setTimeout(() => {
          this.itensFiltro = valores;
        }, 100);
        clearTimeout(this._searchTimerId_3);
        this._searchTimerId_3 = setTimeout(() => {
          searchHighlight('EmpreendimentoConsulta', this.search);
        }, 100);

      }, 450);
    },

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },

    handlePageChange(value) {
      this.currentPage = value;
      this.busca_timeout();
    },

  },
};
</script>

<style scoped>
html {
  overflow-y: hidden;
}

.title-page {
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif !important;
}

table tr {
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_inativo {
  font-size: 14px !important;
  background: #df6262;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.v-btn {
  display: inline-block;
}

.v-data-table-header-mobile {
  background-color: orangered;
  display: none !important;
}

.search-input,
.search-input-longo {
  min-width: 400px;
  max-width: 400px;
  margin: 0.8rem 0 1.2rem 0;
  height: 35px !important;
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px); */
  }
}

.icon-action {
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .btn-icon {
    margin-right: 8px !important;
  }
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.container-rounded {
  background: #fff !important;
}

.v-data-table>table>tbody>tr>th,
.v-data-table>table>tfoot>tr>th {
  /* font-size: 1.8rem !important; */
  font-size: 20px !important;
}

@media (max-width: 1024px) {
  .td-avatar {
    padding-left: 16px !important;
  }

  .nav-link {
    padding-right: 0px !important;
  }
}

@media (max-width: 599px) {

  .container,
  .container-principal {
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #FFF !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .nav-link {
    padding-left: 0px;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }

  .btn-icon {
    margin-right: 0px !important;
  }

  .search-input {
    margin: 0.8rem 0 1.2rem 0;
    min-width: auto;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }

  .container-principal {
    max-width: 100% !important;
  }

  .highlight {
    background-color: #FF6;
  }

}
</style>